exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-julius-tsx": () => import("./../../../src/pages/about-julius.tsx" /* webpackChunkName: "component---src-pages-about-julius-tsx" */),
  "component---src-pages-acend-site-settings-tsx": () => import("./../../../src/pages/acend-site-settings.tsx" /* webpackChunkName: "component---src-pages-acend-site-settings-tsx" */),
  "component---src-pages-admin-stats-tsx": () => import("./../../../src/pages/admin-stats.tsx" /* webpackChunkName: "component---src-pages-admin-stats-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-coupon-stats-tsx": () => import("./../../../src/pages/coupon-stats.tsx" /* webpackChunkName: "component---src-pages-coupon-stats-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pca-certification-tsx": () => import("./../../../src/pages/pca-certification.tsx" /* webpackChunkName: "component---src-pages-pca-certification-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-team-plans-tsx": () => import("./../../../src/pages/team-plans.tsx" /* webpackChunkName: "component---src-pages-team-plans-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-trainings-tsx": () => import("./../../../src/pages/trainings.tsx" /* webpackChunkName: "component---src-pages-trainings-tsx" */),
  "component---src-pages-user-information-tsx": () => import("./../../../src/pages/user-information.tsx" /* webpackChunkName: "component---src-pages-user-information-tsx" */)
}

